import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import InventoryManagement from 'images/blog-images/shipkoo-inventory-management-infographics.png';
import SalesChart from 'images/blog-images/sales-chart.png';
import ShipkooExpressPlus from 'images/blog-images/shipkoo-express-plus-packages.png'
import InventoryManagementFeatured from 'images/featured-image/shipkoo-inventory-management-infographics.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query InventoryManagementQuery {
      file(relativePath: { eq: "shipkoo-inventory-management-infographics.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="Why is it Important for Small Business to have Inventory Management"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet
        // title="Why is it Important for Small Business to have Inventory Management | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-whay-is-it-important" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Why is it Important for Small Business to have Inventory Management | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-whay-is-it-important" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: InventoryManagementFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="why-is-it-important-for-small-business-to-have-inventory-management"
        title={intl.formatMessage({ id: "why-is-it-important-blog-head-title" })}
        date="2021-02-24"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              According to <a href="https://www.statista.com/statistics/379046/worldwide-retail-e-commerce-sales/#:~:text=In%202019%2C%20retail%20e%2Dcommerce,most%20popular%20online%20activities%20worldwide." target="_blank" rel="noreferrer">Statista</a>, in 2019, retail eCommerce sales worldwide amounted to 3.53 trillion US dollars and e-retail revenues are projected to grow to 6.54 trillion US dollars in 2022. Online shopping is one of the most popular online activities worldwide.
            </p>

            <p>
              It is important to evaluate your business on a regular basis to ensure that you are on track to succeed. One of the most integral parts of your business is inventory management. It is important to pay attention to your order fulfillment process. Any order delays can adversely impact company reputation and branding. Having a reliable inventory management process also allows for scalability. It can help create a seamless supply chain that ensures orders are shipped promptly, thereby ensuring customer satisfaction with the product and the service.
            </p>

            <Image src={SalesChart} className="" alt="small business sales chart"/>

            <h5>
              <strong>
                What is inventory management?
              </strong>
            </h5>

            <p>
              Inventory management is the part of supply chain management that aims to always have the right products in the right quantity for sale, at the right time. When done effectively, businesses reduce the costs of carrying excess inventory while maximizing sales. Good inventory management can help you track your inventory in real-time to streamline this process.
            </p>

            <p>
              Good inventory management ensures your products are sold in time to avoid spoilage or obsolescence, or spending too much money on a stock that is taking up space in a warehouse.
            </p>

            <Image src={InventoryManagement} alt="shipkoo crowdfunding fulfillment" />

            <h5>
              <strong>
                Inventory management techniques and best practices for small business
              </strong>
            </h5>
            <p>
              <strong>
                Perpetual Inventory Management
              </strong>
            </p>
            <p>
              It is considered the most basic inventory management technique. Often, merchants use spreadsheets or pen and paper to track inventory when implementing this technique. Modern systems do away with this antiquated tracking method and automate it as goods arrive. It is vital to look for software with <Link to="/resources/all-in-one-digital-platform/">real-time sales analytics</Link>. Shipkoo’s software connects directly to your point of sale, so your stock levels are automatically adjusted every time you make a sale. Receive daily stock alert emails so you always know which items are low or out of stock so you can order more in time.
            </p>

            <p>
              <strong>
                Track your stock levels at all times
              </strong>
            </p>

            <p>
              Have a solid system in place for tracking your stock levels, prioritizing the most expensive products. Effective software saves you time and money by doing much of the heavy lifting for you. Ordering the right number of products to stock is vital to keep inventory balanced.
            </p>

            <p>
              <strong>
                Identify low-turn stock
              </strong>
            </p>

            <p>
              If you have stock that has not sold at all in the last 6 months, it is probably time to stop stocking that item. You might also consider different strategies for getting rid of that stock — like a special discount or promotion so as to save your warehouse storage cost and space.
            </p>

            <p>
              <strong>
                Dropshipping
              </strong>
            </p>

            <p>
              If your business adopts drop shipping methods, you can sell products without actually holding the inventory yourself. Dropshipping companies do not have stock on hand. Instead, these operations fulfill orders by ordering from a supplier once a customer buys a particular product. Many owners who start an online store adopt drop shipping methods, but this supply chain fulfillment strategy can be adopted by many types of businesses across all industries.
            </p>

            <p>
              <strong>
                Quality control
              </strong>
            </p>

            <p>
              No matter your specialty, it is important to ensure that all your products look great and are working well. It could be as simple as having staff do a quick examination during stock audits that includes a checklist for signs of damage and correct product labeling.
            </p>

            <Image src={ShipkooExpressPlus} alt="shipkoo express plus" />

            <h5>
              <strong>
                Advantages of proper inventory management
              </strong>
            </h5>

            <p>
              <strong>
                Fulfillment optimization
              </strong>
            </p>

            <p>
              You will increase visibility and transparency. Using inventory management, companies can collect and analyze data to better grasp safety stock levels to fulfill orders easier. By being transparent about inventory status and fulfillment options, companies can assure customers of any supply chain issues, such as handling more stringent measures due to COVID 19.
            </p>

            <p>
              <strong>
                Cost Reduction
              </strong>
            </p>

            <p>
              Inventory management allows an operation to save money and boost business productivity. When a company has accurate data about their inventory, they are empowered to make the right business decisions. It can help procure the right products for the business and automatically reorder them once stocks get low. It can also reduce supplier lead time and obsolete inventory. It also helps you to save storage space and cost at the same time.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare>
          </div>
          <Clear></Clear>
       </BlogRow>
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;
